import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import React from 'react';
import '../assets/stylesheets/pages/author.scss';

export default function Author({ data }) {
  const {
    markdownRemark: { html, frontmatter },
  } = data;
  return (
    <div className="author-container">
      <div className="author-content">
        <h1>{frontmatter.headline}</h1>
        <h2>{frontmatter.subhead}</h2>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
}

Author.propTypes = {
  data: PropTypes.any,
};

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        headline
        subhead
      }
    }
  }
`;
